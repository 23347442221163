<template>
    <v-text-field
        :label="systemaction.description" 
        dark
        filled
        :color="$theme.primary"
        :prefix="`${systemaction.bonus_type}: `" 
        v-if="systemaction.bonus_type !== 'no'" 
        flat 
        class="mt-3" 
        v-model="systemaction.bonus_value"
        @click:append="updateSystemAction(systemaction)"
        append-icon="mdi-content-save"
    ></v-text-field>

    <!-- <div v-else class="expande-horizontal centraliza mt-2">
        <span class="white--text"> NÃO PRECISA DE CONFIGURAÇÕES ADICIONAIS! </span>
    </div> -->
</template>

<script>
import { mapActions } from 'vuex'
export default {
    props: ['systemaction'],
    methods: {
        ...mapActions([
            "updateSystemAction"
        ])
    }
}
</script>