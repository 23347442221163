<template>
  <layout :title="$route.name" :options="options">
    <div class="expande-horizontal wrap">
      <v-flex xs12 class="pa-12 py-2">
        <h3 class="fonte orange--text">INFORMATIVO!</h3>
        <span class="fonte font-weight-thin white--text">
          Para desativar o pagamento de rendimento diário, deixa o valor em 0 ou vazio, depois salve clicando no diskete no fim do campo digitável.
        </span>
      </v-flex>
      <div class="expande-horizontal wrap centraliza">
        <v-flex v-for="item in getSystemActions" :key="item._id" xs12 md4 class="px-2">
          <SistemActionItem :systemaction="item" />
        </v-flex>
      </div>
    </div>
  </layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SistemActionItem from './components/SystemActionItem.vue'
export default {
  components: {
    SistemActionItem
  },
  data() {
    return {
      options: []
    }
  },
  computed: {
    ...mapGetters(["getSystemActions"])
  },
  methods: {
    ...mapActions(["listSystemActions"])
  },
  created() {
    this.listSystemActions();
  }
};
</script>
